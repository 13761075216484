export const navigations = [
    // {
    //     name: 'Dashboard',
    //     path: '/dashboard/default',
    //     icon: 'dashboard',
    // },
    // {
    //     label: 'PAGES',
    //     type: 'label',
    // },
    {
        name: 'Projects',
        path: '/projects/list',
        icon: 'tablet_android',
    },
    {
        name: 'Employee',
        path: '/employee/list',
        icon: 'accessibility',
    },
    {
        name: 'Time Sheet',
        path: '/attendance/list',
        icon: 'build',
    },
    {
        name: 'Consumables',
        path: '/consumables',
        icon: 'chat',
    },
    {
        name: 'Calendar',
        path: '/calendar',
        icon: 'date_range',
    },
]
