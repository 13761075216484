export const SET_IS_LOGGED = 'SET_IS_LOGGED';
export const SET_LOGGED_DATA = 'SET_LOGGED_DATA'
export const SET_STAFF_LISTS = 'SET_STAFF_LISTS';
export const SET_ITEMS_LISTS = 'SET_ITEMS_LISTS';
export const SET_IS_INITIALIZE = 'SET_IS_INITIALIZE';
export const SET_MEETINGS_LISTS = 'SET_MEETINGS_LISTS';
export const SET_PROJECTMODEL_LISTS = 'SET_PROJECTMODEL_LISTS';
export const SET_OVERLAY_STATE = 'SET_OVERLAY_STATE';



